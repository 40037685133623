<template>
  <div id="centerRight2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="align-left" class="text-icon"></icon>
        </span>
        <span class="text mx-2">就业残疾人年龄段分布</span>
      </div>
      <div style="width:100%;height:45%" id="agechart"></div>
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="align-left" class="text-icon"></icon>
        </span>
        <span class="text mx-2">就业残疾人户口占比</span>
      </div>
      <div style="width:100%;height:45%" id="person"></div>
    </div>
  </div>
</template>

<script>
// import centerRight2Chart1 from '@/components/echart/centerRight/centerRightChart'
import * as echarts from "echarts"

export default {
  data() {
    return {
      water: {
        data: [54, 88],
        shape: "roundRect",
        formatter: "{value}%",
        waveNum: 3,
      },
      config: {
        data: [
          {
            name: "多重",
            value: 290,
          },
          {
            name: "言语",
            value: 56,
          },
          {
            name: "精神",
            value: 186,
          },
          {
            name: "听力",
            value: 453,
          },
          {
            name: "视力",
            value: 724,
          },
          {
            name: "智力",
            value: 1000,
          },
          {
            name: "肢体",
            value: 2990,
          },
        ],
      },
      configEmpoye: {
        data: [
          {
            name: "帮扶性就业",
            value: 7705,
          },
          {
            name: "城市公告服务岗位",
            value: 268,
          },
          {
            name: "大学生就业",
            value: 247,
          },
        ],
        colors: ["#e062ae", "#e690d1", "#32c5e9", "#96bfff"],
        // unit: "人",
        // showValue: true,
      },
      attackSourcesColor1: [
        "#5470C6",
        "#1E9FFF",
        "#00CA94",
        "rgba(84,112,198,.52)",
      ],
      attackSourcesColor: [
        // "rgba(255,85,127,1)",
        // "rgba(255,170,0,1)",
        // "rgba(0,202,148,1)",
        // "rgba(30,159,255,.52)",
        "rgba(224,98,174,1)",
      ],
      stationData: [
        "18-25",
        "26-36",
        "36-40",
        "40-45",
        "45-50",
        "50-55",
        "55-60",
      ],
      MaxData: [226, 844, 593, 641, 899, 1446, 659],
      values: [135, 631, 524, 538, 211, 0, 0],
      seriesName: ["女", "男"],
    }
  },
  mounted() {
    this.ageOptions()
    this.OfflineOptions()
  },
  methods: {
    ageOptions() {
      var chartDom = document.getElementById("agechart")
      var myChart = echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },

        grid: {
          left: "8%",
          right: "2%",
          bottom: "3%",
          top: "17%",
          containLabel: true,
        },
        color: ["#E062AE", "#1C5E98"],
        legend: {
          data: this.seriesName,
          textStyle: {
            color: "#fff",
          },
          top: "5%",
        },
        xAxis: {
          type: "category",

          // splitLine: {
          //   show: true,
          //   lineStyle: {
          //     color: "#fff",
          //   },
          // },

          axisLabel: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
            rotate: 30,
          },

          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
          data: this.stationData,
        },
        yAxis: [
          {
            type: "value",

            // inverse: true,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#fff",
              },
            },
            axisTick: {
              show: false,
            },

            axisLabel: {
              show: false,
              // margin: 30,
              fontSize: 12,
              align: "left",
              padding: [3, 0, 0, 0],
              color: "#3DE7C9",
            },
          },

          // {
          //   type: "category",
          //   inverse: true,
          //   axisTick: "none",
          //   axisLine: "none",
          //   show: true,
          //   axisLabel: {
          //     // textStyle: {
          //     //   color: "#3DE7C9",
          //     //   fontSize: "14",
          //     // },
          //   },
          //   data: this.dataFormat(this.values),
          // },

          // {
          //   // 条状标题
          //   type: "category",
          //   inverse: true,
          //   offset: 15,
          //   position: "left",
          //   axisTick: "none",
          //   axisLine: "none",
          //   show: true,
          //   axisLabel: {
          //     interval: 0,
          //     color: ["#fff"],
          //     align: "left",
          //     verticalAlign: "bottom",
          //     lineHeight: 20,
          //     fontSize: 14,
          //   },
          //   data: this.dataFormat(this.stationData),
          // },
        ],
        series: [
          {
            zlevel: 1,
            name: this.seriesName[0],
            type: "bar",
            barWidth: 16,
            data: this.dataFormat(this.values),
            align: "center",
            itemStyle: {
              normal: {
                // barBorderRadius: 10,
              },
            },
            label: {
              //条状中的样式
              show: true,
              fontSize: 12,
              color: "#fff", //条装中字体颜色
              // textBorderWidth: 2,
              // padding: [2, 0, 0, 0],
            },
          },
          {
            name: this.seriesName[1],
            type: "bar",
            barWidth: 16,
            barGap: "-100%",
            data: this.MaxData,
            itemStyle: {
              normal: {
                color: "rgba(43,162,206,.4)",
                //width:"100%",
                fontSize: 12,
                // barBorderRadius: 30,
              },
            },
            label: {
              //条状中的样式
              show: true,
              fontSize: 12,
              position: "top",
              // textAlign: "right",
              color: "#37F4F1", //条装中字体颜色
              textBorderWidth: 2,
              // padding: [2, 0, 0, 0],
            },
          },
        ],
      }
      myChart.setOption(option)
      window.addEventListener("resize", function() {
        myChart.resize() //下面可以接多个图
      })
    },

    dataFormat(data) {
      var arr = []
      data.forEach((item) => {
        let itemStyle = {
          // color: this.attackSourcesColor[i],
          color: "rgba(224,98,174,1)",
        }
        arr.push({
          value: item,
          itemStyle: itemStyle,
        })
      })
      return arr
    },
    OfflineOptions() {
      var offlinechartDom = document.getElementById("person")
      var offlineChart = echarts.init(offlinechartDom)

      var scaleData = [
        {
          name: "城镇",
          value: 3053,
        },
        {
          name: "农村",
          value: 5613,
        },
      ]

      // var scaleData = [
      //   {
      //     name: "工程建设",
      //     value: 10,
      //   },
      //   {
      //     name: "产权交易",
      //     value: 20,
      //   },
      //   {
      //     name: "土地交易",
      //     value: 20,
      //   },
      //   {
      //     name: "其他交易",
      //     value: 27,
      //   },
      //   {
      //     name: "政府采购",
      //     value: 23,
      //   },
      // ]
      var rich = {
        white: {
          color: "#ddd",
          align: "center",
          padding: [5, 0],
        },
      }
      var placeHolderStyle = {
        normal: {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          color: "rgba(0, 0, 0, 0)",
          borderColor: "rgba(0, 0, 0, 0)",
          borderWidth: 0,
        },
      }
      var data = []
      for (var i = 0; i < scaleData.length; i++) {
        data.push(
          {
            value: scaleData[i].value,
            name: scaleData[i].name,
            itemStyle: {
              normal: {
                borderWidth: 5,
                shadowBlur: 30,
                borderColor: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                  {
                    offset: 0,
                    color: "#7777eb",
                  },
                  {
                    offset: 1,
                    color: "#70ffac",
                  },
                ]),
                shadowColor: "rgba(142, 152, 241, 0.6)",
              },
            },
          },
          {
            value: 4,
            name: "",
            itemStyle: placeHolderStyle,
          }
        )
      }
      var seriesObj = [
        {
          name: "",
          type: "pie",
          clockWise: false,
           radius: [40, 55],
          hoverAnimation: false,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "outside",
                color: "#ddd",
                formatter: function(params) {
                  var percent = 0
                  var total = 0
                  for (var i = 0; i < scaleData.length; i++) {
                    total += scaleData[i].value
                  }
                  percent = ((params.value / total) * 100).toFixed(0)
                  if (params.name !== "") {
                    return params.name + "\n{white|" + "占比" + percent + "%}"
                  } else {
                    return ""
                  }
                },
                rich: rich,
              },
              labelLine: {
                show: false,
              },
            },
          },
          data: data,
        },
      ]
      var option = {
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: seriesObj,
      }
      offlineChart.setOption(option)
      window.addEventListener("resize", function() {
        offlineChart.resize() //下面可以接多个图
      })
    },
  },
  // components: { centerRight2Chart1 }
}
</script>

<style lang="scss" scoped>
.water {
  width: 100%;
  .dv-wa-le-po {
    height: 11rem;
  }
}
#centerRight2 {
  $box-height: 444px;
  $box-width: 300px;
  padding: 5px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    padding: 5px;
    height: $box-height;
    width: $box-width;
    border-radius: 10px;
  }
  .text {
    color: #fff;
    text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
      0 0 40px #38cefe;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-cap-chart {
      width: 100%;
      height: 170px;
    }
  }
}
</style>
