<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="text mx-2">残疾人培训类型分布</span>
          <!-- <dv-decoration-3 class="dv-dec-3" /> -->
        </div>
      </div>
      <div class="d-flex jc-center">
        <CenterLeft1Chart />
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div
          class="item-box mt-2"
          v-for="(item, index) in numberData"
          :key="index"
        >
          <div class="d-flex" style="display:flex;just-contentfy:center">
            <!-- <span class="coin" style="width:1.5rem"> </span> -->
            <!-- #32BAA4 -->
            <dv-digital-flop class="dv-digital-flop" :config="item.number" />
          </div>
          <p style="text-align: center; ">
            {{ item.text }}
            <span class="colorYellow">({{ item.unit }})</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterLeft1Chart from "@/components/echart/centerLeft/centerLeft1Chart"
const formatter = (number) => {
  const numbers = number
    .toString()
    .split("")
    .reverse()
  const segs = []

  while (numbers.length) segs.push(numbers.splice(0, 3).join(""))

  return segs
    .join(",")
    .split("")
    .reverse()
    .join("")
}
export default {
  data() {
    return {
      numberData: [
        {
          number: {
            number: [10308],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "新增培训",
          unit: "时",
        },

        {
          number: {
            number: [111302],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "线下培训",
          unit: "时",
        },
        {
          number: {
            number: [112508],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "线上培训",
          unit: "时",
        },
        {
          number: {
            number: [112],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "运营事件统计",
          unit: "个",
        },

        {
          number: {
            number: [138],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "新增报名人数",
          unit: "个",
        },
        {
          number: {
            number: [81],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "本月新增岗位",
          unit: "个",
        },
      ],
    }
  },
  components: {
    CenterLeft1Chart,
  },
  mounted() {
    // this.changeTiming()
  },
  methods: {
    changeTiming() {
      setInterval(() => {
        this.changeNumber()
      }, 30000)
    },
    changeNumber() {
      this.numberData.forEach((item, index) => {
        item.number.number[0] += ++index
        item.number = { ...item.number }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$box-width: 300px;
$box-height: 450px;

#centerLeft1 {
  padding: 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #fff;
    text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
      0 0 40px #38cefe;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    margin-top: 0.875rem;
    .item-box {
      & > div {
        padding-right: 5px;
      }
      font-size: 10px;
      float: right;
      position: relative;
      width: 33.33%;
      color: #d3d6dd;
      .dv-digital-flop {
        width: 120px;
        height: 30px;
      }
      // 金币
      .coin {
        position: relative;
        top: 6px;
        font-size: 20px;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
      p {
        text-align: center;
      }
    }
  }
}
</style>
