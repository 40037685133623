<template>
  <div class="centerPage">
    <div id="zh" class="map"></div>
    <div class="centerBottom">
      <div class="detail-list">
        <div
            class="detail-list-item"
            v-for="(item, index) in detailsList"
            :key="index"
        >
          <img
              :src="
              require(`../../assets/images/center-details-data${index + 1}.png`)
            "
              :alt="item.title"
          />
          <div class="detail-item-text">
            <h3>{{ item.title }}</h3>
            <span>{{ item.number }}</span>
            <span class="unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import 'echarts/map/js/china';

export default {
  data() {
    const citys = [
      {
        name: '长春',
        value: [125.323544, 43.817072, 8],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '张家口',
        value: [116.801346, 40.35874, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '青岛',
        value: [122.12042, 37.513068, 32],
        symbolSize: 3,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '杭州',
        value: [120.15507, 30.274085, 10],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '贵阳',
        value: [106.630154, 26.647661, 2],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '河北',
        value: [114.475704, 38.584854, -19],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '深圳',
        value: [114.057868, 22.543099, 14],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '黄浦',
        value: [121.484443, 31.231763, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      // {
      //   name: "吉林",
      //   value: [126.549572, 43.837883, -364],
      //   symbolSize: 14,
      //   itemStyle: {
      //     normal: {
      //       color: "#58B3CC",
      //     },
      //   },
      // },
      {
        name: '浙江',
        value: [120.152792, 30.267447, -2],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '北京',
        value: [116.407526, 39.90403, -14],
        symbolSize: 8,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '大同',
        value: [113.61244, 40.040295, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '齐齐哈尔',
        value: [126.661669, 45.742347, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '咸阳',
        value: [108.708991, 34.329605, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '四川',
        value: [104.075931, 30.651652, -5],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '福建',
        value: [119.295144, 26.10078, -1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '庆安',
        value: [127.507825, 46.880102, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      // {
      //   name: "海淀",
      //   value: [116.298056, 39.959912, 32],
      //   symbolSize: 3,
      //   itemStyle: {
      //     normal: {
      //       color: "#F58158",
      //     },
      //   },
      // },
      {
        name: '大兴',
        value: [116.341395, 39.726929, 3],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '青岛',
        value: [120.38264, 36.067082, 52],
        symbolSize: 3,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '朝阳',
        value: [116.443108, 39.92147, 17],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '沈阳',
        value: [123.431475, 41.805698, 41],
        symbolSize: 3,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '双城',
        value: [126.312745, 45.383263, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '南京',
        value: [118.796877, 32.060255, 17],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      // {
      //   name: '新疆',
      //   value: [87.627704, 43.793026, -2],
      //   symbolSize: 2,
      //   itemStyle: {
      //     normal: {
      //       color: '#58B3CC',
      //     },
      //   },
      // },
      {
        name: '成都',
        value: [104.066541, 30.572269, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '陕西',
        value: [108.954239, 34.265472, -2],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '温州',
        value: [120.699367, 27.994267, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '石家庄',
        value: [114.51486, 38.042307, 4],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '邢台',
        value: [114.504844, 37.070589, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '义乌',
        value: [120.075058, 29.306841, 3],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '广州',
        value: [113.264435, 23.129163, 13],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '佳木斯',
        value: [130.318917, 46.799923, 2],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '临汾',
        value: [111.518976, 36.088005, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '上海',
        value: [121.473701, 31.230416, 44],
        symbolSize: 3,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '内蒙古',
        value: [111.765618, 40.817498, -23],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },

      {
        name: '潍坊',
        value: [119.161756, 36.706774, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '苏州',
        value: [120.585316, 31.298886, 14],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '大连',
        value: [121.614682, 38.914003, 40],
        symbolSize: 3,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '广西',
        value: [108.327546, 22.815478, -1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },

      {
        name: '郑州',
        value: [113.625368, 34.7466, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '湖北',
        value: [114.341862, 30.546498, -4],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },

      {
        name: '厦门',
        value: [118.089425, 24.479834, 3],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '哈尔滨',
        value: [126.534967, 45.803775, 8],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '秦皇岛',
        value: [119.600493, 39.935385, 7],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '江苏',
        value: [118.763232, 32.061707, -1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },

      {
        name: '烟台',
        value: [121.447935, 37.463822, 24],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '延安',
        value: [109.489727, 36.585455, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '天津',
        value: [117.200983, 39.084158, 28],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      // {
      //   name: "北京",
      //   value: [116.222982, 39.906611, 3],
      //   symbolSize: 2,
      //   itemStyle: {
      //     normal: {
      //       color: "#F58158",
      //     },
      //   },
      // },
      {
        name: '长沙',
        value: [112.938814, 28.228209, 5],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '安徽',
        value: [117.284923, 31.861184, -1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '昆山',
        value: [120.980737, 31.385598, 4],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '廊坊',
        value: [116.683752, 39.538047, 4],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '黑龙江',
        value: [126.661669, 45.742347, -198],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '西藏',
        value: [91.117212, 29.646923, -1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '河南',
        value: [113.274379, 34.445122, 0],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '湖南',
        value: [112.98381, 28.112444, -1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '佛山',
        value: [113.121416, 23.021548, 2],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '扬州',
        value: [119.412966, 32.39421, 5],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '唐山',
        value: [118.180194, 39.630867, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '武汉',
        value: [114.305393, 30.593099, 2],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '合肥',
        value: [117.227239, 31.820587, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '荆州',
        value: [112.239741, 30.335165, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '山东',
        value: [117.020359, 36.66853, -6],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '西安',
        value: [108.940175, 34.341568, 3],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '济南',
        value: [117.12, 36.651216, 4],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '辽宁',
        value: [123.42944, 41.835441, -58],
        symbolSize: 3,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '山西',
        value: [112.562398, 37.873532, -3],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#58B3CC',
          },
        },
      },
      {
        name: '呼和浩特',
        value: [111.749181, 40.842585, 2],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '重庆',
        value: [106.551557, 29.56301, 2],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },

      {
        name: '宁波',
        value: [121.550357, 29.874557, 10],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '滨海',
        value: [119.820831, 33.990334, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '太原',
        value: [112.548879, 37.87059, 2],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
      {
        name: '兰山',
        value: [118.347707, 35.051729, 1],
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: '#F58158',
          },
        },
      },
    ];
    const moveLines = [
      {
        fromName: '北京',
        toName: '黑龙江',
        coords: [
          [116.407526, 39.90403],
          [126.661669, 45.742347],
        ],
      },
      {
        fromName: '北京',
        toName: '山东',
        coords: [
          [116.407526, 39.90403],
          [117.020359, 36.66853],
        ],
      },
      {
        fromName: '北京',
        toName: '天津',
        coords: [
          [116.407526, 39.90403],
          [117.200983, 39.084158],
        ],
      },
      {
        fromName: '北京',
        toName: '河北',
        coords: [
          [116.407526, 39.90403],
          [114.475704, 38.584854],
        ],
      },
      {
        fromName: '北京',
        toName: '上海',
        coords: [
          [116.407526, 39.90403],
          [121.473701, 31.230416],
        ],
      },
      {
        fromName: '北京',
        toName: '南京',
        coords: [
          [116.407526, 39.90403],
          [118.796877, 32.060255],
        ],
      },
      {
        fromName: '北京',
        toName: '杭州',
        coords: [
          [116.407526, 39.90403],
          [120.15507, 30.274085],
        ],
      },
      {
        fromName: '北京',
        toName: '四川',
        coords: [
          [116.407526, 39.90403],
          [104.075931, 30.651652],
        ],
      },
      {
        fromName: '北京',
        toName: '青岛',
        coords: [
          [116.407526, 39.90403],
          [120.38264, 36.067082],
        ],
      },
      {
        fromName: '北京',
        toName: '苏州',
        coords: [
          [116.407526, 39.90403],
          [120.585316, 31.298886],
        ],
      },
      {
        fromName: '北京',
        toName: '烟台',
        coords: [
          [116.407526, 39.90403],
          [121.447935, 37.463822],
        ],
      },
      {
        fromName: '北京',
        toName: '广州',
        coords: [
          [116.407526, 39.90403],
          [113.264435, 23.129163],
        ],
      },
      {
        fromName: '天津',
        toName: '石家庄',
        coords: [
          [117.200983, 39.084158],
          [114.51486, 38.042307],
        ],
      },

      {
        fromName: '河北',
        toName: '佳木斯',
        coords: [
          [114.475704, 38.584854],
          [130.318917, 46.799923],
        ],
      },

    ];
    const toNames = moveLines.map(item => item.toName);
    const cityList = citys.filter(item => toNames.includes(item.name)).concat({
      name: '北京',
      value: [116.407526, 39.90403, -14],
      symbolSize: 8,
      itemStyle: {
        normal: {
          color: '#58B3CC',
        },
      },
    },);
    return {
      mapData: {
        citys: cityList,
        moveLines,
      },
      detailsList: [
        {
          title: '赋能机构数量',
          number: '310',
          unit: '个',
        },
        {
          title: '服务城市数量',
          number: '60',
          unit: '个',
        },
        {
          title: '链接爱心企业',
          number: 3000,
          unit: '家',
        },
        {
          title: '培训课程数量',
          number: 87,
          unit: '门',
        },
        {
          title: '就业岗位类型',
          number: 28,
          unit: '类',
        },
        {
          title: '文娱活动数量',
          number: '1,023',
          unit: '次',
        },
      ],
    };
  },
  mounted() {
    this.mapOptions();
  },
  methods: {
    mapOptions() {
      var chartDom = document.getElementById('zh');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          show: false,
          text: '全国国华助残图',
          left: 'center',
          textStyle: {
            color: '#fff',
          },
        },
        legend: {
          show: false,
        },
        geo: [
          {
            nameMap: {
              China: '中国',
            },
            map: 'china',
            label: {
              emphasis: {
                show: false,
              },
            },
            zoom: 1.2,
            itemStyle: {
              normal: {
                borderColor: 'rgba(147, 235, 248, 1)',
                borderWidth: 1,
                areaColor: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [{
                    offset: 0,
                    color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                  }],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: 'rgba(128, 217, 248, 1)',
                // shadowColor: 'rgba(255, 255, 255, 1)',
                shadowOffsetX: -4,
                shadowOffsetY: 10,
                shadowBlur: 10
              },
              emphasis: {
                areaColor: '#389BB7',
                borderWidth: 0
              }
            }
          },
        ],
        series: [
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            showEffectOn: 'render',
            zlevel: 1,
            rippleEffect: {
              period: 10,
              scale: 18,
              brushType: 'fill'
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                offset: [15, 0],
                color: '#f39919',
                show: true
              },
            },
            itemStyle: {
              normal: {
                color: '#1DE9B6',
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            symbolSize: 12,
            data: this.mapData.citys,
          },
          {
            type: 'lines',
            zlevel: 2,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.6, //特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: 'arrow', //箭头图标
              symbolSize: 8, //图标大小
            },
            lineStyle: {
              normal: {
                color: '#ffb206',
                width: 1, //线条宽度
                opacity: 0.1, //尾迹线条透明度
                curveness: .3 //尾迹线条曲直度
              }
            },
            data: this.mapData.moveLines,
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener('resize', function () {
        myChart.resize(); //下面可以接多个图
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.centerPage {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.map {
  width: 53.125rem;
  height: 40.625rem;
}

.centerBottom {
  display: flex;
  margin-bottom: 1.25rem;
  margin-top: 4.375rem;
  width: 100%;
  height: 16.25rem;

  .detail-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: space-around;
    width: 100%;

    &-item {
      display: flex;
      align-items: center;
      position: relative;
      height: 7.8125rem;
      padding: 0 0.625rem;
      width: 32%;
      border-radius: 5px;
      border: 1px solid #343f4b;
      // background-color: rgba(19, 25, 47, 0.8);
      background-color: rgba(13, 28, 83, 0.3);

      img {
        width: 6.25rem;
        height: 6.25rem;
      }

      .detail-item-text {
        margin-left: 0.625rem;

        h3 {
          color: #bcdcff;
          font-size: 16px;
          margin-bottom: 1.25rem;
        }

        span {
          font-weight: 500px;
          font-size: 1.25rem;
          font-weight: bolder;
          background: linear-gradient(to bottom, #fff, #4db6e5);
          color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        }

        .unit {
          font-size: 1rem;
          margin-left: 0.625rem;
        }
      }
    }
  }
}
</style>
