<template>
  <div class="left">
    <div class="leftTop">
      <dv-borderBox12 class="left-top-borderBox12">
        <div class="left-top">
          <div class="module-title">
            <span style="margin-right: 4px">
              <icon name="chart-line" class="text-icon"></icon>
            </span>
            <span>残疾人服务时间趋势图</span>
          </div>
          <div class="title-dis">
            <!-- <span>
              平均接纳次数(小时):
              <span class="title-dis-keyword">{{ accessFrequency }}次</span>
            </span>
            <span>
              流量峰值:
              <span class="title-dis-keyword">{{ peakFlow }}M</span>
            </span> -->
          </div>
          <!-- <TrafficSituation trafficSitua={trafficSitua}></TrafficSituation> -->
          <div id="today" class="left_today"></div>
        </div>
      </dv-borderBox12>
    </div>

    <div class="leftButtom">
      <dv-borderBox13 class="left-bottom-borderBox13">
        <div class="left-bottom">
          <div class="module-title">
            <span style="margin-right: 4px">
              <icon name="chart-bar" class="text-icon"></icon>
            </span>
            <span>残疾学员名录</span>
          </div>
          <dv-scrollBoard :config="config" class="user-data"></dv-scrollBoard>
        </div>
      </dv-borderBox13>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    return {
      config: {
        // 表头背景色
        headerBGC: '#443dc5',
        // 奇数行背景色
        oddRowBGC: '',
        // #09184F
        // 偶数行背景色
        evenRowBGC: '',
        // #070C34
        // 行号
        index: true,
        // 行号表头
        indexHeader: '序号',
        // 宽度
        columnWidth: [100, 80, 80, 80, 100],
        // 对其方式
        align: ['center'],
        // 表行数
        rowNum: 10,
        header: ['姓名', '年龄', '性别', '残疾类别'],
        data: [
          ['余*慧', '46', '女', '肢体'],
          ['*鸣', '35', '女', '肢体'],
          ['朱*强', '53', '男', '肢体'],
          ['*文', '56', '男', '精神'],
          ['丁*茹', '28', '女', '精神'],
          ['*孔亮', '37', '男', '精神'],
          ['殷*生', '35', '男', '肢体'],
          ['李*婷', '33', '女', '精神'],
          ['程*梅', '33', '女', '智力'],
          ['*蕾', '39', '女', '多重'],
          ['杨*岭', '43', '男', '肢体'],
          ['冯*凯', '16', '男', '多重'],
          // ["梁*鸥", "24", "男", "智力"],
          // ["杨*宇", "28", "男", "肢体"],
        ],
      },
      accessFrequency: 1500,
      peakFlow: 300,
      trafficSitua: {
        timeList: [
          '11.05',
          '11.06',
          '11.07',
          '11.08',
          '11.09',
          '11.10',
          '11.11',
          '11.12',
          '11.13',
          '11.14',
          '11.15',
          '11.16',
          '11.17',
          '11.18',
          '11.19',
        ],
        outData: [4, 3, 2, 1, 2, 3, 4, 3, 1, 4, 2, 2, 2, 19, 20, 22],
        inData: [4, 3, 2, 2, 2, 8, 9, 7, 9, 9, 10, 20, 30, 50, 52, 53],
      },
    };
  },
  mounted() {
    this.trafficOptions();
    // this.config = {
    //   // 表头背景色
    //   headerBGC: "#443dc5",
    //   // 奇数行背景色
    //   oddRowBGC: "#09184F",
    //   // 偶数行背景色
    //   evenRowBGC: "#070C34",
    //   // 行号
    //   index: true,
    //   // 行号表头
    //   indexHeader: "序号",
    //   // 宽度
    //   columnWidth: [50, 100, 200],
    //   // 对其方式
    //   align: ["center"],
    //   // 表行数
    //   rowNum: 10,
    //   header: ["用户", "时间", "状态"],
    //   data: [
    //     ["用户1", "2020-11-11 12:00", "在线"],
    //     ["用户2", "2020-11-11 12:00", "离线"],
    //     ["用户3", "2020-11-11 12:00", "在线"],
    //     ["用户4", "2020-11-11 12:00", "在线"],
    //     ["用户5", "2020-11-11 12:00", "在线"],
    //     ["用户6", "2020-11-11 12:00", "离线"],
    //     ["用户7", "2020-11-11 12:00", "在线"],
    //     ["用户8", "2020-11-11 12:00", "在线"],
    //     ["用户9", "2020-11-11 12:00", "离线"],
    //     ["用户11", "2020-11-11 12:00", "离线"],
    //     ["用户12", "2020-11-11 12:00", "离线"],
    //     ["用户13", "2020-11-11 12:00", "离线"],
    //   ],
    // }
  },
  methods: {
    trafficOptions() {
      var chartDom = document.getElementById('today');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          show: false,
        },
        legend: {
          show: true,
          top: '1%',
          textStyle: {
            color: '#c0c9d2',
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0, 255, 233,0)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255, 255, 255,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(0, 255, 233,0)',
                  },
                ],
                global: false,
              },
            },
          },
        },
        grid: {
          top: '15%',
          left: '15%',
          right: '10%',
          bottom: '10%',
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
          },
          splitArea: {
            color: '#f00',
            lineStyle: {
              color: '#f00',
            },
          },
          axisLabel: {
            color: '#BCDCF0',
          },
          splitLine: {
            show: false,
          },
          boundaryGap: false,
          data: this.trafficSitua.timeList,
        },

        yAxis: [
          {
            type: 'value',
            name: '学员考勤数据',
            nameTextStyle: {
              color: '#d1e6eb',
            },
            min: 0,
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255,255,255,0.1)',
              },
            },
            axisLine: {
              show: true,
            },
            axisLabel: {
              show: true,
              margin: 10,
              textStyle: {
                color: '#d1e6eb',
              },
            },
            axisTick: {
              show: false,
            },
          },
          {
            type: 'value',
            min: 0,
            name: '服务人次',
            nameTextStyle: {
              color: '#d1e6eb',
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255,255,255,0.1)',
              },
            },
            axisLine: {
              show: true,
            },
            axisLabel: {
              show: true,
              margin: 10,
              textStyle: {
                color: '#d1e6eb',
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '学员考勤数据',
            type: 'line',
            smooth: true, //是否平滑
            lineStyle: {
              normal: {
                color: '#00b3f4',
                shadowColor: 'rgba(0, 0, 0, .3)',
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: false,
              position: 'top',
              textStyle: {
                color: '#00b3f4',
              },
            },
            // 去除点标记
            symbolSize: 0,
            // 鼠标放上去还是要有颜色的
            itemStyle: {
              color: '#00b3f4',
            },
            // 设置渐变色
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(0,179,244,0.3)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(0,179,244,0)',
                      },
                    ],
                    false
                ),
                shadowColor: 'rgba(0,179,244, 0.9)',
                shadowBlur: 20,
              },
            },
            data: this.trafficSitua.outData,
          },
          {
            name: '服务人次',
            type: 'line',
            yAxisIndex: 1,
            smooth: true, //是否平滑
            // 阴影
            lineStyle: {
              normal: {
                color: '#00ca95',
                shadowColor: 'rgba(0, 0, 0, .3)',
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: false,
              position: 'top',
              textStyle: {
                color: '#00ca95',
              },
            },
            // 去除点标记
            symbolSize: 0,
            itemStyle: {
              color: '#00ca95',
            },
            // 设置渐变色
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(0,202,149,0.3)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(0,202,149,0)',
                      },
                    ],
                    false
                ),
                shadowColor: 'rgba(0,202,149, 0.9)',
                shadowBlur: 20,
              },
            },
            data: this.trafficSitua.inData,
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener('resize', function () {
        myChart.resize(); //下面可以接多个图
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.left {
  width: 30rem;
  height: auto;
  // padding: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 0px;
}

.leftTop {
  position: relative;
  height: 21.875rem;
  width: 100%;

  .left-top-borderBox12 {
    width: inherit;
    height: inherit;
    padding: 10px;

    .left-top {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(13, 28, 83, 0.3);

      .title-dis {
        margin-top: 0.9375;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 1rem;
        color: #c0c9d2;

        &-keyword {
          padding-left: 0.625rem;
          color: #47dae8;
        }
      }
    }
  }
}

.leftButtom {
  position: relative;
  margin-top: 1.25rem;
  height: 38.75rem;
  width: 100%;

  .left-bottom-borderBox13 {
    width: inherit;
    height: inherit;
    padding: 1.25rem 0.9375rem;

    .left-bottom {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(13, 28, 83, 0.3);
    }
  }
}

.module-title {
  padding: 10px 10px;
  color: #bcdcff;
  font-size: 1rem;
  font-weight: bold;

  .iconfont {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    color: #89e5ff;
    font-weight: 400;
  }
}

.left_today {
  width: 26.875rem;
  height: 16.625rem;
}

.user-data {
  width: 27.375rem;
  height: 34.375rem;
}
</style>
